import styled from 'styled-components';
import { Grid } from '../GridWrapper';
import { HeaderNav } from './header-components/HeaderNav';
import { HeaderLogo } from './header-components/HeaderLogo';
import { HeaderTitle } from './header-components/HeaderTitle';
import React, { useEffect, useState } from "react"
import { useMediaQuery } from 'react-responsive'


interface HeaderStyledProps {
    show?: boolean
    sticky?: boolean
}

const HeaderWrapper = styled.header<HeaderStyledProps>`
    height:60px;
    background:${({ sticky }) => sticky ? 'rgba(0,0,0,0.75)' : 'var(--transparent)'};
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 4;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 17%);
    z-index:10;
    @media screen and (min-width: 768px)
    {
        position:relative;
        height:60px;

        //border-bottom:1px solid var(--border-color);
    }
`;

const HeaderDiv = styled.div`
    position: relative;
    display: flex;
    height:60px;
`;



export const Header = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const [sticky, setHeaderSticky] = useState(false);


    function setMobileHeader() {
        if (window.pageYOffset !== 0 && isTabletOrMobile) {
            setHeaderSticky(true);
        }
        else {
            setHeaderSticky(false);
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", setMobileHeader);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", setMobileHeader);
        };
    });

    return (
        <HeaderWrapper sticky={sticky}>
            <Grid>
                <HeaderDiv>
                    <HeaderLogo />
                    <HeaderTitle />
                    <HeaderNav />
                </HeaderDiv>
            </Grid>
        </HeaderWrapper>
    )
}
