import { Modal } from "../../atom/default/Modal"
import { ICourse, ICompetition } from "../../../models"
import { CompetitionUnsubscribe } from "../forms/CompetitionUnsubscribe"
import React from "react"

interface CompetitionUnsubscribeProps {
    visible: boolean
    onClose: () => void
    course: ICourse
    competition: ICompetition
}

export const CompetitionUnsubscribeModal = ({ visible, onClose, course, competition }: CompetitionUnsubscribeProps) => {
    return (
        <CompetitionUnsubscribe visible={visible} onClose={onClose} competition={competition} course={course} />
    )
}
