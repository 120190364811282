import { Grid } from "../layout/GridWrapper"
import CourseImageBox from "../molecules/course/CourseImageBox"
import { ICourse, ICompetition, IPlayer } from "../../models"
import CourseInfoBox from "../molecules/course/CourseInfoBox"
import styled, { css } from 'styled-components';
import { getCourse } from "../../utils/api/courses"
import React from "react"
import { sortCompetitions, getYear, sortCompetitionsOnYear } from "../../utils/helpers";

interface CompetitionBoxStyledProps {
    finished?: boolean
}

const CompetitionSection = styled.section`
    width:100%;
    min-height:100vh;
    height:auto;
    padding-top:80px;
`;

const CompetitionBoxWrapper = styled.div<CompetitionBoxStyledProps>`
    display:flex;
    flex-direction:column;
    margin-bottom:20px;
    position:relative;

    @media only screen and (min-width: 768px)
    {
        flex-direction:row;
    }

    ${({ finished }) =>

        finished && css`
            opacity:.5;
            pointer-events:none;

            &:after {
                content: "Avslutad";
                color: red;
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 40px;
                z-index: 20;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight:900;
                font-size:3rem;
                @media only screen and (max-width: 768px)
                {
                    top: 25%;
                }
            }
        `
    }
`;


interface CompetitionTemplateProps {
    courses: ICourse[]
    competitions: ICompetition[]
}

const CompetitionTemplate = ({ courses, competitions }: CompetitionTemplateProps) => {
    const year = getYear();
    competitions = sortCompetitions(competitions);
    competitions = sortCompetitionsOnYear(competitions);

    const setCourse = (competition: ICompetition) => {
        let course: any = getCourse(courses, competition.courseId);
        return (<>
            <CourseImageBox src={course.courseImage} />
            <CourseInfoBox competition={competition} course={course} />
        </>)
    }

    return (
        <CompetitionSection id="competitions">
            <Grid>
                {competitions.map((competition, index) =>
                    <CompetitionBoxWrapper finished={false} key={index}>
                        {setCourse(competition)}
                    </CompetitionBoxWrapper>
                )}
            </Grid>
        </CompetitionSection>
    )
}
export default CompetitionTemplate

// //competitionFinished(competition.competitionDate)
