import {
  IPlayerScoreCard,
  ICompetition,
  ICourse,
  IScoreCard,
  IPlayer,
} from "../models";
import { useState, useEffect } from "react";
import { getPlayers } from "./api/players";

export const clearFields = (container: any) => {
  var selects = container.getElementsByTagName("select");
  for (var i = 0, len = selects.length; i < len; i++) {
    selects[i].selectedIndex = -1;
  }

  var fields = container.getElementsByTagName("input");
  for (var i = 0, len = fields.length; i < len; i++) {
    var field = fields[i];
    switch (field.type) {
      case "radio":
      case "checkbox":
        field.checked = false;
        break;

      case "text":
      case "password":
      case "hidden":
      case "number":
        field.value = "";
    }
  }

  var fields = container.getElementsByTagName("textarea");
  for (var i = 0, len = fields.length; i < len; i++) {
    fields[i].value = "";
  }
};

export const dateInBetween = (from: string, to: string, check: string) => {
  var fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
};

export const isDateBeforeToday = (date: any) => {
  var fDate, cDate;
  fDate = Date.parse(date);
  cDate = Date.parse(new Date().toISOString());
  if (cDate >= fDate) {
    return true;
  }
  return false;
};

export const isDateAfterToday = (date: any) => {
  var fDate, cDate;
  fDate = Date.parse(date);
  cDate = Date.parse(new Date().toISOString());
  if (cDate <= fDate) {
    return true;
  }
  return false;
};

export const returnPlayerScorecards = (
  id: number,
  scoreCards: IPlayerScoreCard[],
  getAll: boolean = false
) => {
  let playerScoreCards = scoreCards.filter((scoreCard: IPlayerScoreCard) => {
    return scoreCard.playerId == id;
  });

  if (
    Object.keys(playerScoreCards).length === 0 &&
    playerScoreCards.constructor === Object
  ) {
    return [];
  }
  return playerScoreCards;
};

export const returnPlayerScorecardsForYear = (
  id: number,
  scoreCards: IPlayerScoreCard[],
  getAll: boolean = false
) => {
  let playerScoreCards = scoreCards.filter((scoreCard: IPlayerScoreCard) => {
    return (
      scoreCard.playerId == id &&
      scoreCard.competitionDate.substring(0, 4) === getYear().toString()
    );
  });

  if (
    Object.keys(playerScoreCards).length === 0 &&
    playerScoreCards.constructor === Object
  ) {
    return [];
  }
  return playerScoreCards;
};

export const returnCompetition = (id: number, competitions: ICompetition[]) => {
  return competitions.find((competition: ICompetition) => {
    return competition.competitionId == id;
  });
};

export const returnCourse = (id: number, courses: ICourse[]) => {
  return courses.find((course: ICourse) => {
    return course.courseId == id;
  });
};

export const returnPlayerScorecardHoleScore = (
  id: number,
  scoreCard: IScoreCard[]
) => {
  return scoreCard.find((score: IScoreCard) => {
    return score.hole == id;
  });
};

export const returnPlayerPointsBirdies = (arr: any) => {
  const yearCompetitions = arr.filter(
    (o) => o.competitionDate.substring(0, 4) === getYear().toString()
  );
  if (yearCompetitions.length !== 0) {
    let points = yearCompetitions
      .map((o) => o.points)
      .reduce((a, c) => {
        return a + c;
      });
    let birdies = yearCompetitions
      .map((o) => o.birdies)
      .reduce((a, c) => {
        return a + c;
      });
    return { points: points, birdies: birdies };
  }
  return { points: 0, birdies: 0 };
};

export const sortCompetitions = (competitions: ICompetition[]) => {
  return competitions.sort(function (a, b) {
    var da = new Date(a.competitionDate).getTime();
    var db = new Date(b.competitionDate).getTime();
    return da < db ? -1 : da > db ? 1 : 0;
  });
};

export const sortPlayerscorecards = (playerScorecards: IPlayerScoreCard[]) => {
  return playerScorecards.sort(function (a, b) {
    var da = new Date(a.competitionDate).getTime();
    var db = new Date(b.competitionDate).getTime();
    return da < db ? -1 : da > db ? 1 : 0;
  });
};

export const sortPlayers = (players: IPlayer[]) => {
  return players.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const setScoreColor = (par: number, score: number) => {
  if (score == 1) {
    return "--score-hio";
  }
  let value = score - par;
  switch (value) {
    case -3:
      return "--score-albatross";
    case -2:
      return "--score-eagle";
    case -1:
      return "--score-birdie";
    case 0:
      return "--score-par";
    case 1:
      return "--score-bogey";
    case 2:
      return "--score-double-bogey";
    case 3:
    case 4:
    case 5:
      return "--score-worse";
    default:
      return "--score-no-result";
  }
};

export const setLowestScore = (scoreCards) => {
  let result = scoreCards
    .filter((o) => o.competitionDate.slice(0, 4) === getYear().toString())
    .map(({ points, competitionId }) => ({ points, competitionId }));
  const count =
    result.filter((obj) => obj.points === 0 && obj.competitionId !== 6).length +
    6 -
    result.length;
  if (result.length > 5 && count <= 1) {
    let min = Math.min(
      ...result.filter((obj) => obj.points !== 0).map((item) => item.points)
    );
    const scoreCard = result.filter((item) => item.points === min);
    return scoreCard[0];
  }
  return {
    points: 0,
    competitionId: 0,
  };
};

export const sortCompetitionsOnYear = (competitions) => {
  return competitions.filter((comp) => {
    return comp.competitionYear === getYear().toString();
  });
};

export const getYear = () => {
  const d = new Date();
  let year = d.getFullYear();
  return year;
};

export const isHidden = (el) => {
  return el.offsetParent === null;
};
