import React, { useEffect, useState } from "react";
import "./styles/main.scss";
import CompetitionTemplate from "./components/templates/CompetitionTemplate";
import { PageWrapper } from "./components/layout/PageWrapper";
import { getCourses } from "./utils/api/courses";
import { getCompetitions } from "./utils/api/competitions";
import PlayersTemplate from "./components/templates/PlayersTemplate";
import { getPlayers } from "./utils/api/players";
import HomeTemplate from "./components/templates/HomeTemplate";
import { getPlayerScorecards } from "./utils/api/playerScorecards";
import { CreateCourse } from "./components/organisms/CreateCourse";
import RankingTemplate from "./components/templates/RankingTemplate";
import { CreateComptetition } from "./components/organisms/CreateCompetition";
import { UpdateComptetition } from "./components/organisms/UpdateCompetition";
import { CreateScorecard } from "./components/organisms/CreateScorecard";
import { UpdateScorecard } from "./components/organisms/UpdateScorecard";
import LiveScore from "./components/templates/LiveScore";
import DeleteCompetition from "./components/organisms/DeleteCompetition";

function App() {
  const [courses, setCourses] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playerScorecards, setPlayerScorecards] = useState([]);

  useEffect(() => {
    async function loadData() {
      setCourses(await getCourses());
      setCompetitions(await getCompetitions());
      setPlayerScorecards(await getPlayerScorecards());
      setPlayers(await getPlayers());
    }
    loadData();
  }, []);

  return (
    <div className="home">
      <PageWrapper>
        <HomeTemplate courses={courses} competitions={competitions} />
        <CompetitionTemplate competitions={competitions} courses={courses} />
        <PlayersTemplate
          playersScorecards={playerScorecards}
          competitions={competitions}
          courses={courses}
          players={players}
        />
        <RankingTemplate
          courses={courses}
          playersScorecards={playerScorecards}
          competitions={competitions}
          players={players}
        />
        {/* 
           <UpdateComptetition
          players={players}
          courses={courses}
          competitions={competitions}
        />
        <UpdateScorecard
          players={players}
          competitions={competitions}
          playersScorecards={playerScorecards}
        /> */}
        {/* <UpdateScorecard players={players} competitions={competitions} playersScorecards={playerScorecards} /> */}
        {/*Ska döljas på sidan senare
        // <CreateCourse />
        <CreateComptetition courses={courses} competitions={competitions} />
        <UpdateComptetition players={players} courses={courses} competitions={competitions} />
         <CreateScorecard players={players} competitions={competitions} />
            <UpdateScorecard players={players} competitions={competitions} playersScorecards={playerScorecards} />
                    <DeleteCompetition competitions={competitions} playersScorecards={playerScorecards} />
        */}
      </PageWrapper>
    </div>
  );
}

export default App;
