import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebase'
import { IPlayer } from "../../models";

export const getPlayers = async () => {
    let data: any = [];
    const querySnapshot = await getDocs(collection(db, "players"));
    querySnapshot.forEach((doc) => {
        if (doc.data().active) {
            data.push(doc.data());
        }
    });
    return data;
}

export const returnPlayers = async (ids: number[]) => {
    let players = await getPlayers();
    let filteredPlayers = players.filter((player: IPlayer) => {
        return ids.includes(player.id);
    })
    return filteredPlayers;
}
