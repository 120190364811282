import { collection, getDocs, updateDoc, doc, addDoc } from "firebase/firestore";
import { db } from '../firebase'
import { ICourse } from "../../models";


export const getCourse = (courses: ICourse[], id: number) => {
    let course = courses.find(course => course.courseId === id);
    return course;
}

export const getCourses = async () => {

    let data: any = [];
    const querySnapshot = await getDocs(collection(db, "courses"));
    querySnapshot.forEach((doc) => {
        data.push(doc.data());
    });
    return data;
}

export const postCourse = async (course: ICourse) => {
    try {
        const docRef = await addDoc(collection(db, "courses"), course);
        course.docId = docRef.id
        let obj = { ...course }
        let result = await updateCourse(obj);
    } catch (e) {
    }
}

export const updateCourse = async (course: ICourse) => {
    const ref = await doc(db, "courses", `${course.docId}`);
    await updateDoc(ref, { ...course })
        .then(() => {
            return Promise.resolve(true);
        })
        .catch((error) => {
            return Promise.resolve(false);
        });
}
