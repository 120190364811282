import styled from 'styled-components';
import React from "react"

const PlayerNameWrapper = styled.div``;

interface PlayerNameProps {
    name: string
}

export const PlayerName = ({ name }: PlayerNameProps) => {
    return (
        <PlayerNameWrapper>
            {name}
        </PlayerNameWrapper>
    )
}
