import styled, { css } from 'styled-components';
import React from "react";

interface ButtonStyledProps {
    background?: string
    color?: string
    width?: string
    borderRadius?: boolean
}

const ButtonWrapper = styled.button<ButtonStyledProps>`
    width:${({ width }) => width};
    height:40px;
    border:0;
    font-weight:700;
    color:${({ color }) => `var(${color})`};
    background:${({ background }) => `var(${background})`};
    ${({ borderRadius }) =>
        borderRadius && css`
        border-radius:2px;
        border-bottom-left-radius:10px;
        border-top-right-radius:10px;
    `}}
    @media screen and (max-width: 768px)
    {
        width:${({ width }) => width};
    }
`;

interface ButtonProps {
    background?: string
    color?: string
    width?: string
    children?: React.ReactNode
    borderRadius?: boolean
    onClick?: () => void
}

export const Button = ({ background = "--accent-orange", color = "--white", width = "100%", children, borderRadius = true, onClick }) => {
    return (
        <ButtonWrapper onClick={onClick} background={background} color={color} width={width} borderRadius={borderRadius}>{children}</ButtonWrapper>
    )
}
