import { IPlayer, ICompetition, ICourse, IPlayerScoreCard } from "../../models";
import { Grid } from "../layout/GridWrapper";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import {
  returnPlayerScorecards,
  returnPlayerPointsBirdies,
  sortCompetitions,
  setLowestScore,
  getYear,
  sortCompetitionsOnYear,
  returnPlayerScorecardsForYear,
} from "../../utils/helpers";
import { Paragraph } from "../atom/default/Paragraph";
import Row from "../atom/default/Row";
import { ViewPlayerModal } from "../molecules/player/ViewPlayerModal";

interface RankingStyledProps {
  alignment?: string;
}

const RankingSection = styled.section`
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: 80px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
`;

const TableThead = styled.thead``;

const TableTbody = styled.tbody``;

const TableTr = styled.tr`
  &:nth-child(even) {
    background-color: #fff;
  }
`;

const TableTh = styled.th<RankingStyledProps>`
  text-align: ${({ alignment }) => alignment};
  padding: 8px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const TableTd = styled.td<RankingStyledProps>`
  cursor: default;
  &.pointer {
    cursor: pointer;
  }

  &.line-through {
    text-decoration: line-through;
  }

  text-align: ${({ alignment }) => alignment};
  padding: 8px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

interface PlayersProps {
  players: IPlayer[];
  courses: ICourse[];
  competitions: ICompetition[];
  playersScorecards: IPlayerScoreCard[];
}

const RankingTemplate = ({
  players,
  courses,
  playersScorecards,
  competitions,
}: PlayersProps) => {
  const year = getYear();
  const data: any = players;
  const [showViewPlayerModal, setShowViewPlayerModalModal] = useState(false);
  const [showWolkesLeague, setShowWolkesLeague] = useState(false);
  const [player, setPlayer] = useState(null);
  const [playerScoreCards, setPlayerScorecards] = useState(null);
  let [columns, setColumns] = useState(["Pos", "Namn", "Poäng"]);
  const [sortedPlayers, setSortPlayers] = useState([]);
  const [playerModalCompetitions, setPlayerModalCompetitions] = useState([]);
  const [yearCompetitions, setYearCompetitions] = useState([]);

  const setInitalColumns = () => {
    return showWolkesLeague
      ? ["Pos", "Namn", "Birdies"]
      : ["Pos", "Namn", "Poäng"];
  };

  const rankingPlayers = players.map((player) => {
    let scoreCards = returnPlayerScorecards(player.id, playersScorecards);
    const lowest = setLowestScore(scoreCards);
    let playerPoints =
      scoreCards.length !== 0
        ? returnPlayerPointsBirdies(scoreCards)
        : { points: 0, birdies: 0 };
    return {
      ...player,
      scoreCards: scoreCards,
      points:
        playerPoints.points === 0 ? 0 : playerPoints.points - lowest.points,
      birdies: playerPoints.birdies,
    };
  });

  const setPlayerCompetitionScores = (scoreCards) => {
    let rows = [];
    const obj = setLowestScore(scoreCards);
    yearCompetitions.map((competition, index) => {
      let style = !showWolkesLeague
        ? obj.competitionId === 0
          ? ""
          : obj.competitionId === competition.competitionId
          ? "line-through"
          : ""
        : "";
      let scoreCardRes = scoreCards.find(
        (scoreCard) => scoreCard.competitionId === competition.competitionId
      );
      let row = scoreCardRes ? (
        <TableTd className={style} key={index} alignment={"center"}>
          {showWolkesLeague ? scoreCardRes.birdies : scoreCardRes.points}
        </TableTd>
      ) : (
        <TableTd key={index} alignment={"center"}>
          0
        </TableTd>
      );
      rows.push(row);
    });
    return rows;
  };

  const sortPlayers = () => {
    let players = [];
    if (showWolkesLeague) {
      players = rankingPlayers.sort((a, b) => {
        return (
          b.birdies - a.birdies ||
          b.points - a.points ||
          a.name.localeCompare(b.name)
        );
      });
    } else {
      players = rankingPlayers.sort((a, b) => {
        return (
          b.points - a.points ||
          b.birdies - a.birdies ||
          a.name.localeCompare(b.name)
        );
      });
    }
    players = players.filter((player) => player.id !== 32);
    setSortPlayers(players);
    //return players;
  };

  const showRankingPlayer = (player) => {
    setPlayer(player);
    setPlayerScorecards(player.scoreCards);
    setShowViewPlayerModalModal(true);
  };

  useEffect(() => {
    sortPlayers();
  }, [players, showWolkesLeague]);

  useEffect(() => {
    setColumns([]);
    if (competitions.length !== 0) {
      let columns: any = setInitalColumns();
      setPlayerModalCompetitions(sortCompetitions(competitions));
      let comps = sortCompetitionsOnYear(competitions);
      let arr: any = [];
      comps.map((competition) => arr.push(competition.competitionName));
      setYearCompetitions(comps);
      setColumns(columns.concat(arr));
    }
  }, [competitions, showWolkesLeague]);

  return (
    <RankingSection id="ranking">
      <Grid>
        <Row
          padding="10px 0"
          mobilePadding={"10px 0"}
          justifyContent="flex-start"
        >
          <Paragraph
            padding="0 0.5rem 0 0"
            onClick={() => setShowWolkesLeague(false)}
            fontWeight={showWolkesLeague ? "400" : "700"}
          >
            Poängligan {year}{" "}
          </Paragraph>{" "}
          |{" "}
          <Paragraph
            padding="0 0 0 0.5rem"
            onClick={() => setShowWolkesLeague(true)}
            fontWeight={showWolkesLeague ? "700" : "400"}
          >
            Wolkesligan {year}{" "}
          </Paragraph>
        </Row>
        <TableWrapper>
          <Table>
            <TableThead>
              <TableTr>
                {columns.map((column, index) => (
                  <TableTh
                    alignment={index === 0 || index === 1 ? "left" : "center"}
                    key={index}
                  >
                    {column}
                  </TableTh>
                ))}
              </TableTr>
            </TableThead>
            <TableTbody>
              {sortedPlayers.map((player, index) => (
                <TableTr key={index}>
                  <TableTd>{index + 1}</TableTd>
                  <TableTd
                    className={"pointer"}
                    onClick={() => showRankingPlayer(player)}
                  >
                    {player.name}
                  </TableTd>
                  <TableTd alignment={"center"}>
                    {showWolkesLeague ? player.birdies : player.points}
                  </TableTd>
                  {setPlayerCompetitionScores(player.scoreCards)}
                </TableTr>
              ))}
            </TableTbody>
          </Table>
        </TableWrapper>
        {showViewPlayerModal ? (
          <ViewPlayerModal
            player={player}
            playerScoreCards={player.scoreCards}
            competitions={playerModalCompetitions}
            courses={courses}
            visible={showViewPlayerModal}
            onClose={() => setShowViewPlayerModalModal(false)}
          />
        ) : null}
      </Grid>
    </RankingSection>
  );
};

export default RankingTemplate;
