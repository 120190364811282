import styled from 'styled-components';
import React from "react"
import logo from '../../../../assets/images/skanetouren_logo.png';
import { getYear } from '../../../../utils/helpers';

const HeaderLogoWrapper = styled.div`
    position: relative;
    display: flex;
    top:5px;
    img {
        width:50px;
        height:50px;
    }
    @media screen and (min-width: 768px)
    {
        top: 10px;
        left:0;
        img {
            width: 133px;
            height: 133px;
        }
    }
`;

const HeaderLogoYear = styled.div`
    position: absolute;
    display: flex;
    left: 32px;
    top: 98px;
    width: 70px;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin: 0 auto;
    font-size: 0.625rem;
    color: var(--white);
    @media screen and (max-width: 768px)
    {
        display:none;
    }
`;


export const HeaderLogo = () => {
    const year = getYear();
    return (
        <HeaderLogoWrapper>
            <a href="/">
                <img src={logo} />
            </a>
            <HeaderLogoYear>{year}</HeaderLogoYear>
        </HeaderLogoWrapper>
    )
}
