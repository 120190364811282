import styled from 'styled-components';
import Heading from '../../atom/default/Heading';
import { useState, useEffect } from 'react';
import { Select } from '../../atom/default/Select';
import { getPlayers, returnPlayers } from '../../../utils/api/players';
import { ICompetition, ICourse } from '../../../models';
import { updateCompetition } from '../../../utils/api/competitions';
import { Paragraph } from '../../atom/default/Paragraph';
import React from "react"
import { Button } from '../../atom/default/Button';
import { useMediaQuery } from 'react-responsive';
import { getPlayerScorecards, deletePlayerScorecard } from '../../../utils/api/playerScorecards';
import { sortPlayers } from '../../../utils/helpers';
import { Modal } from '../../atom/default/Modal';


const CompetitionUnsubscribeWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    min-height:500px;
`;

interface CompetitionUnsubscribeProps {
    visible: boolean
    onClose: () => void
    course: ICourse
    competition: ICompetition
}

export const CompetitionUnsubscribe = ({ visible, onClose, course, competition }: CompetitionUnsubscribeProps) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const [isModalVisible, setModalVisible] = useState(false);
    const [showUnsubribeButton, setShowUnsubribeButton] = useState(true);
    const [players, setPlayers] = useState([]);
    const [unsubscribePlayerText, setUnsubscribePlayerText] = useState("");

    const filterPlayers = async (ids: number[]) => {
        let players = await returnPlayers(ids);
        let sortedPlayers = sortPlayers(players);
        setPlayers(sortedPlayers);
        setModalVisible(true);
    }

    const unsubscribePlayer = async () => {
        let playerId: any = document.getElementById("playerSelection");
        const scoreCards = await getPlayerScorecards();
        let playerScorecard = scoreCards.find((scoreCard) => scoreCard.competitionId == competition.competitionId && scoreCard.playerId == parseFloat(playerId.value));
        const filtered = competition.registrationIds.filter((id) => {
            return id !== parseFloat(playerId.value)
        });
        competition.registrationIds = filtered;

        const deleteRes = await deletePlayerScorecard(playerScorecard.docId);
        const res = await updateCompetition(competition);

        if (deleteRes && res) {
            setShowUnsubribeButton(false);
            filterPlayers(filtered);
            setUnsubscribePlayerText("Du är nu avanmäld från tävlingen (detta kommer inte per automatik avboka dig från klubben utan du måste själv kontakta klubben och göra en avbokning av din tid.)")
            setTimeout(() => {
                window.location.reload();
            }, 10000);
        }
    }

    useEffect(() => {
        if (visible) {
            filterPlayers(competition.registrationIds);
        }
    }, [visible, competition])

    return (
        <Modal visible={isModalVisible} onClose={onClose}>
            <CompetitionUnsubscribeWrapper>
                <Heading align="center">Avboka dig från {competition.competitionName}</Heading>
                <Select id="playerSelection" items={players} />
                {showUnsubribeButton ? <Button width={isTabletOrMobile ? '100%' : '50%'} background={"--black"} onClick={unsubscribePlayer}>Avboka dig</Button> : null}
                <Paragraph>{unsubscribePlayerText ?? ''}</Paragraph>
            </CompetitionUnsubscribeWrapper>
        </Modal>
    )
}
