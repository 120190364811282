import styled from 'styled-components';
import CourseImage from '../../atom/course/CourseImage';
import React from "react"

const CourseImageBoxWrapper = styled.div`
    display:flex;
    width:100%;
    flex-basis:100%;
    min-height: 300px;
    object-fit: cover;
    max-height: 300px;
    background-color:#f7f7f7;

    @media only screen and (max-width: 376px)
    {
        min-height: 250px;
        max-height: 250px;
    }

    @media only screen and (min-width: 768px)
    {
        width:50%;
        flex-basis:50%;
        min-height: 400px;
        object-fit: cover;
        max-height: 400px;
    }
`;

interface CourseImageBoxProps {
    src: string
}

const CourseImageBox = ({ src }: CourseImageBoxProps) => {
    return (
        <CourseImageBoxWrapper>
            <CourseImage src={src} />
        </CourseImageBoxWrapper>
    )
}

export default CourseImageBox
