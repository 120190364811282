import React from "react"
import Icon from "./Icon"

interface MenuIconProps {
    color?: string
}

export const MenuIcon = ({ color = "black" }: MenuIconProps) => {
    return (
        <Icon viewBox="0 0 140 140" className={`menu-icon ${color}`}>
            <g transform="matrix(10,0,0,10,0,0)">
                <g>
                    <line className="menu-icon" x1="13.5" y1="2" x2="0.5" y2="2"></line>
                    <line className="menu-icon" x1="13.5" y1="7" x2="0.5" y2="7"></line>
                    <line className="menu-icon" x1="13.5" y1="12" x2="0.5" y2="12"></line>
                </g>
            </g>
        </Icon>
    )
}