import styled from 'styled-components';
import { IPlayer, ICompetition, ICourse, IPlayerScoreCard } from '../../models';
import React, { useState, useEffect } from "react"
import { ViewPlayerModal } from './player/ViewPlayerModal';
import { returnPlayerScorecards, returnPlayerPointsBirdies, setLowestScore } from '../../utils/helpers';
import medal from '../../assets/medal.png';

interface PlayerCardStyledProps {
    backgroundImage?: string
}

const PlayerCardWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-basis: calc(100%);
    width: calc(100%);
    align-items:center;
    margin-bottom:16px;
    background:var(--white);
    padding:20px;
    border:1px solid var(--border-color);
    border-top-left-radius:10px;
    border-bottom-right-radius:10px;

    @media screen and (min-width: 468px) and (max-width: 768px)
    {
        flex-basis: calc(50% - 8px);
        width: calc(50% - 8px);
        &:nth-child(2n) {
            margin-left:16px;
        }
    }

    @media screen and (min-width: 768px)
    {
        flex-basis: calc(25% - 8px);
        width: calc(25% - 8px);
        margin-right:8px;
        &:first-child {
            //margin-left:0;
        }

        &:hover {
            box-shadow: rgb(0 0 0 / 8%) 0px 3px 15px 0px;
        }
    }
`;

const PlayerCardBox = styled.div`
    position: relative;
    height:auto;
    width:100%;
`;

const PlayerCardHeader = styled.div<PlayerCardStyledProps>`
  position: relative;
  //height: 220px;
  background-size: cover;
  background-position: top;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  border-bottom-left-radius:10px;
  border-top-right-radius:10px;
  width:100%;
  min-height:200px;
  max-height:200px;
`;

const PlayerCardHeaderBar = styled.div`
    position: absolute;
    top:0;
    width: 100%;
    z-index: 1;
    width: 100%;
`;

const PlayerCardBody = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-top:10px;
`;

const PlayerCardBodyName = styled.span`
    font-size: 20px;
    font-weight: 700;
    text-transform: normal;
    margin: 0 auto;
`;

const PlayerCardBodyHomeclub = styled.span`
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
    color: #919191;
    padding-bottom:20px;
`;

const PlayerCardBodyBio = styled.span`
    font-size: 14px;
    color: #7B7B7B;
    font-weight: 300;
    margin: 10px auto;
    line-height: 20px;
`;


const PlayerCardFooter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
`;

const PlayerCardFooterBox = styled.div`
    box-sizing: border-box;
    width: calc(100% / 3);
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    position:relative;

    &:not(:last-child)
    {
        &:after {
            content: "";
            border-right: 1px solid #EBEBEB;
            height: 17px;
            padding-left: 10px;
            position: absolute;
            right: 0px;
        }
    }
`;

const PlayerCardFooterLabel = styled.span`
    display: block;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 11px;
    letter-spacing:1px;
    color: #95989A;
`;

const PlayerCardFooterText = styled.span`
    display: block;
    font-weight: 700;
    font-size: 20px;
    margin-top: 5px;
`;

interface PlayerProps {
    player: IPlayer
    competitions: ICompetition[]
    courses: ICourse[]
    playersScorecards: IPlayerScoreCard[]
}

export const Player = ({ player, competitions, courses, playersScorecards }: PlayerProps) => {
    const [showViewPlayerModal, setShowViewPlayerModalModal] = useState(false);
    const [playerBirdies, setPlayerBirdies] = useState(0);
    const [playerPoints, setPlayerPoints] = useState(0);
    const [playerScoreCards, setPlayerScorecards] = useState<IPlayerScoreCard[]>([]);

    useEffect(() => {
        let playerScorecards: IPlayerScoreCard[] = returnPlayerScorecards(player.id, playersScorecards)
        const lowest = setLowestScore(playerScorecards);
        if (playerScorecards.length !== 0) {
            let res = returnPlayerPointsBirdies(playerScorecards);
            setPlayerBirdies(res.birdies);
            setPlayerPoints(res.points - lowest.points);
        }
        setPlayerScorecards(playerScorecards);

    }, [player.id])

    return (
        <>
            <PlayerCardWrapper>
                <PlayerCardBox onClick={() => setShowViewPlayerModalModal(true)}>
                    <PlayerCardHeader backgroundImage={player.profileImage}>
                        <PlayerCardHeaderBar>
                            {/*<a href="#" class="btn-message"><span class="sr-only">Message</span></a>
              <a href="#" class="btn-menu"><span class="sr-only">Menu</span></a>*/}
                        </PlayerCardHeaderBar>
                    </PlayerCardHeader>

                    <PlayerCardBody>
                        <PlayerCardBodyName>{player.name}</PlayerCardBodyName>
                        <PlayerCardBodyHomeclub>{player.homeClub}</PlayerCardBodyHomeclub>
                        {/*<PlayerCardBodyBio>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos, aperiam.</PlayerCardBodyBio>*/}
                    </PlayerCardBody>

                    <PlayerCardFooter>
                        <PlayerCardFooterBox>
                            <PlayerCardFooterLabel>Poäng</PlayerCardFooterLabel>
                            <PlayerCardFooterText>{playerPoints}</PlayerCardFooterText>
                        </PlayerCardFooterBox>
                        <PlayerCardFooterBox>
                            <PlayerCardFooterLabel>Birdies</PlayerCardFooterLabel>
                            <PlayerCardFooterText>{playerBirdies}</PlayerCardFooterText>
                        </PlayerCardFooterBox>
                        <PlayerCardFooterBox>
                            <PlayerCardFooterLabel>Hcp</PlayerCardFooterLabel>
                            <PlayerCardFooterText>{player.golfHcp}</PlayerCardFooterText>
                        </PlayerCardFooterBox>
                    </PlayerCardFooter>
                </PlayerCardBox>
            </PlayerCardWrapper>
            {showViewPlayerModal ? <ViewPlayerModal player={player} playerScoreCards={playerScoreCards} competitions={competitions} courses={courses} visible={showViewPlayerModal} onClose={() => setShowViewPlayerModalModal(false)} /> : null}
        </>
    )
}
