import styled from 'styled-components';
import React from "react";
import { Headings } from '../../../types';

interface StyledHeadingProps {
    align?: string
    padding?: string
}

const StyledHeading = styled.h1<StyledHeadingProps>`
  font-style: normal;
  color: var(--black);
  margin:0;
  padding-bottom:0.5rem;
  text-align:${({ align }) => align};
  padding:${({ padding }) => padding};
  @media screen and (max-width: 768px)
  {
      font-size:14px;
  }
`;

interface HeadingProps {
    size?: Headings
    align?: string
    padding?: string
    children: React.ReactNode
}

const Heading = ({ size = "h1", align = "left", children, padding }: HeadingProps) => {
    return (
        <StyledHeading padding={padding} align={align} as={`${size}`}>
            {children}
        </StyledHeading>
    )
};

export default Heading;
