import styled from 'styled-components';
import React from "react"

const CourseImageWrapper = styled.img`
    display: block;
    height: auto;
    max-width: 100%;
    margin:0 auto;
`;

interface CourseImageProps {
    src: string
}

const CourseImage = ({ src }: CourseImageProps) => {
    return (
        <CourseImageWrapper src={src} alt="" />
    )
}
export default CourseImage
