import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledOverlay = styled.div<OverlayProps>`
  background: ${({ transparent }) => (transparent ? 'transparent' : 'rgba(0, 0, 0, 0.25)')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex || 5};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface OverlayProps {
    zIndex?: number;
    visible?: boolean;
    transparent?: boolean;
    disableClick?: boolean;
    onClose?: () => void;
}

const Overlay: React.FunctionComponent<OverlayProps & React.HTMLAttributes<HTMLDivElement>> = (
    props,
) => {
    const { visible, onClose, disableClick } = props;

    const ovRef = useRef<HTMLDivElement>(null);

    const handleClick = (e: any) => {
        if (!disableClick && ovRef.current && e.target === ovRef.current && onClose) onClose();
    };

    if (!visible) return null;
    return <StyledOverlay ref={ovRef} {...props} onClick={handleClick} />;
};

export default Overlay;