import React from "react"
import Icon from "./Icon"

interface CloseIconProps {
    color?: string
    onClick?: () => void
}

export const CloseIcon = ({ color = "black", onClick }: CloseIconProps) => {
    return (
        <Icon onClick={onClick} viewBox="0 0 140 140" className={`close-icon ${color}`}>
            <g transform="matrix(10,0,0,10,0,0)">
                <g>
                    <line x1="13.5" y1="0.5" x2="0.5" y2="13.5" className="close-icon"></line>
                    <line x1="0.5" y1="0.5" x2="13.5" y2="13.5" className="close-icon"></line>
                </g>
            </g>
        </Icon>
    )
}
