import { Header } from "./header/Header"
import { Footer } from "./Footer"
import { Main } from "./Main"
import React from "react"

interface PageWrapperProps {
    children: React.ReactNode
}

export const PageWrapper = ({ children }: PageWrapperProps) => {
    return (
        <>
            <Main>
                {children}
            </Main>
            <Footer />
        </>
    )
}
