import React from 'react';

interface IconProps {
    viewBox?: string
    className?: string
    children: React.ReactNode
    onClick?: () => void;
}

const Icon = ({ viewBox, className, children, onClick }: IconProps) => {
    return (
        <svg
            onClick={onClick}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
        >
            {children}
        </svg>
    );
};

export default Icon;