import styled from 'styled-components';
import { Direction, Justify, AlignItems } from '../../../types';
import React from "react"

const RowWrapper = styled.div<RowProps>`
    max-width:100%;
    width: ${({ width }) => width};
    display:${({ hide }) => hide ? 'none' : 'flex'};
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    flex-direction: ${({ direction }) => direction};
    padding:${({ padding }) => padding};
    @media screen and (max-width: 768px){
        padding:${({ mobilePadding }) => mobilePadding};
    }
`;

interface RowProps {
    children: React.ReactNode
    direction?: Direction;
    justifyContent?: Justify;
    alignItems?: AlignItems;
    padding?: string
    mobilePadding?: string
    hide?: boolean
    width?: string
    onClick?: () => void
}

const Row = ({ children, direction = 'row', justifyContent = 'flex-start', alignItems = 'stretch', padding = "0", mobilePadding = "0", hide = false, width = "100%", onClick }: RowProps) => {
    return (
        <RowWrapper onClick={onClick} hide={hide} padding={padding} mobilePadding={mobilePadding} direction={direction} justifyContent={justifyContent} alignItems={alignItems} width={width}>{children}</RowWrapper>
    )
}

export default Row
