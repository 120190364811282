import styled from 'styled-components';
import React from "react"

const GridWrapper = styled.div`
    margin: 0px auto;
    padding: 0px 8px;
    max-width: 1280px;
    width: 100%;
`;

interface GridProps {
    children: React.ReactNode
}

export const Grid = ({ children }: GridProps) => {
    return (
        <GridWrapper>
            {children}
        </GridWrapper>
    )
}
