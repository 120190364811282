import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase'
import { IPlayerScoreCard } from "../../models";

export const getPlayerScorecards = async () => {
    let data: any = [];
    const querySnapshot = await getDocs(collection(db, "playerScorecards"));
    querySnapshot.forEach((doc) => {
        data.push(doc.data());
    });
    return data;
}

export const postPlayerScoreCards = async (scoreCards: IPlayerScoreCard[]) => {
    scoreCards.forEach(async (scoreCard) => {
        try {
            const docRef = await addDoc(collection(db, "playerScorecards"), scoreCard);
            scoreCard.docId = docRef.id
            let obj = { ...scoreCard }
            let result = await updatePlayerScorecard(obj);
        } catch (e) {
        }
    });
}

export const postPlayerScoreCard = async (scoreCard: IPlayerScoreCard) => {
    try {
        const docRef = await addDoc(collection(db, "playerScorecards"), scoreCard);
        scoreCard.docId = docRef.id
        let obj = { ...scoreCard }
        let result = await updatePlayerScorecard(obj);
    } catch (e) {
    }
}

export const updatePlayerScorecard = async (scoreCard: IPlayerScoreCard) => {
    const ref = await doc(db, "playerScorecards", `${scoreCard.docId}`);
    return await updateDoc(ref, { ...scoreCard })
        .then(() => {
            return Promise.resolve(true);
        })
        .catch((error) => {
            return Promise.resolve(false);
        });
}

export const deletePlayerScorecard = async (docId: string) => {
    const ref = await doc(db, "playerScorecards", `${docId}`);
    return await deleteDoc(ref)
        .then(() => {
            return Promise.resolve(true);
        })
        .catch((error) => {
            return Promise.resolve(false);
        });
}

