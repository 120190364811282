import { InputTypes } from "../../../types"
import styled from 'styled-components';
import React from "react"

interface InputWrapperStyledProps {
    padding?: string;
}

const InputBox = styled.div<InputWrapperStyledProps>`
    display:flex;
    flex-direction:column;
    padding:${({ padding }) => padding};
    //width:calc(100% - 1rem);
    width:100%;
    //margin-right:1rem;
`;

const InputWrapper = styled.input`
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

interface InputProps {
    id?: string
    inputTitle: string
    name: string
    type?: InputTypes
    value?: any
    padding?: string
    disabled?: boolean
    onChange?: (target: any) => void
}

const Input = ({ id = "", inputTitle, name, type = "text", value, padding = "1rem 0", disabled = false, onChange, ...rest }: InputProps) => {
    return (
        <InputBox padding={padding}>
            {inputTitle}
            <InputWrapper {...rest} disabled={disabled} id={id} name={name} value={value} type={type} onChange={onChange} />
        </InputBox>
    )
}

export default Input;
