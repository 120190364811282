import styled from 'styled-components';
import React from "react";

const SelectWrapper = styled.select`
    margin:10px 0;
    padding:10px;
    @media screen and (max-width: 768px)
    {
        height:40px;
        width:100%;
    }
`;

interface SelectProps {
    items: any
    id?: string
    defaultValue?: any
    value?: any
    onChangeHandler?: () => void
}
export const Select = ({ items, id, defaultValue, value, onChangeHandler }: SelectProps) => {
    if (!items) return null
    return (<SelectWrapper defaultValue={defaultValue} value={value} id={id} onChange={onChangeHandler}>
        {items.map((item: any, index: number) => <option key={index} value={item.id}>{item.name}</option>)}
    </SelectWrapper>
    )
}
