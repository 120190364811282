import styled from 'styled-components';
import React from "react"
import { ScoreCardColors } from '../../../types';

interface ScoreColorsStyledProps {
    background: ScoreCardColors
}

const ScoreColorsWrapper = styled.div`
   display:flex;
   align-items:center;
   flex-wrap:wrap;
   padding-top:20px;
   @media screen and (max-width: 768px)
   {
       justify-content:center;
      //display:none;
   }
`;

const ScoreLabel = styled.div<ScoreColorsStyledProps>`
   background:${({ background }) => `var(${background})`};
   width:10px;
   height:10px;
`;

const ScoreText = styled.div`
    font-size:0.7rem;
    padding:0 0.5rem;
`;

export const ScoreColors = () => {
    return (
        <ScoreColorsWrapper>
            <ScoreLabel background={"--score-hio"} /><ScoreText>HIO</ScoreText>
            <ScoreLabel background={"--score-albatross"} /><ScoreText>Albatross</ScoreText>
            <ScoreLabel background={"--score-eagle"} /><ScoreText>Eagle</ScoreText>
            <ScoreLabel background={"--score-birdie"} /><ScoreText>Birdie</ScoreText>
            <ScoreLabel background={"--score-par"} /><ScoreText>Par</ScoreText>
            <ScoreLabel background={"--score-bogey"} /><ScoreText>Bogey</ScoreText>
            <ScoreLabel background={"--score-double-bogey"} /><ScoreText>Dubbel B.</ScoreText>
            <ScoreLabel background={"--score-worse"} /><ScoreText>Trippel eller värre.</ScoreText>
            <ScoreLabel background={"--score-no-result"} /><ScoreText>Inget resultat</ScoreText>
        </ScoreColorsWrapper>
    )
}
