import React, { useEffect, useState } from "react";
import { ICompetition } from "../../models";
import styled from "styled-components";
import { Paragraph } from "../atom/default/Paragraph";

const NextCompetitionWrapper = styled.div``;

const NextCompetitionInfo = styled.div``;

interface NextCompetitionProps {
  competitions: ICompetition[];
}

export const NextCompetition = ({ competitions }: NextCompetitionProps) => {
  const setClosestCompetition = () => {
    if (competitions.length === 0) {
      return null;
    } else {
      const today = new Date().getTime();
      const filteredCompetitions = competitions.filter((comp) => {
        return new Date(comp.competitionDate).getTime() > today;
      });

      return filteredCompetitions.length !== 0 ? (
        <NextCompetitionInfo>
          <Paragraph>{filteredCompetitions[0].competitionDate}</Paragraph>
          <Paragraph fontWeight="700">
            {filteredCompetitions[0].competitionName}
          </Paragraph>
          <Paragraph>
            Antal anmälda{" "}
            {
              filteredCompetitions[0].registrationIds.filter((id) => id !== 42)
                .length
            }
          </Paragraph>
        </NextCompetitionInfo>
      ) : null;
    }
  };

  return (
    <NextCompetitionWrapper>
      <Paragraph fontSize={"30px"} fontWeight="700">
        Kommande tävling
      </Paragraph>
      {setClosestCompetition()}
    </NextCompetitionWrapper>
  );
};
