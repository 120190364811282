import styled from 'styled-components';
import { useState } from 'react';
import { CloseIcon } from '../../../atom/icons/CloseIcon';
import { MenuIcon } from '../../../atom/icons/MenuIcon';
import React from "react"
import Overlay from '../../../atom/default/Overlay';

interface HeaderNavStyledProps {
    show?: boolean
}

const HeaderNavWrapper = styled.ul<HeaderNavStyledProps>`
    list-style-type:none;
    display:none;
    margin:0;
    padding:0;
    align-items:center;
    display:flex;
    justify-content:flex-end;
    width:100%;
    @media screen and (max-width: 768px)
    {
        flex-direction:column;
        background:var(--white);
        height:auto;
        display:${({ show }) => show ? 'flex' : 'none'};
        position: fixed;
        top: 60px;
        width: 100%;
        border-top: 1px solid var(--border-color);
        height: auto;
        z-index: 2000;
        margin: 0 -8px;
        justify-content:flex-start;
    }

`;
const HeaderNavItem = styled.li`
    display:flex;
    margin-left:10px;
    color:var(--white);
    @media screen and (max-width: 768px)
    {
        color:var(--black);
        width:100%;
        border-bottom:1px solid var(--border-color);
        justify-content:center;
        padding:10px;
        cursor:pointer;
        &:first-child {
            border-top:1px solid var(--border-color);
        }
    }
`;

const HeaderNavIcon = styled.button`
    display:flex;
    align-items:center;
    width:50px;
    background:var(--transparent);
    border:0;
    outline:0;
    justify-content: flex-end;
    @media screen and (min-width: 768px)
    {
        display:none;
    }
`;

const HeaderNavItemLink = styled.a``;

export const HeaderNav = () => {
    const [showMobileNav, setShowMobileNav] = useState(false);

    return (
        <>
            <HeaderNavIcon onClick={() => setShowMobileNav(!showMobileNav)}>
                {showMobileNav ? <CloseIcon color={"white"} /> : <MenuIcon color={"white"} />}
            </HeaderNavIcon>
            <Overlay style={{ top: "60px" }} visible={showMobileNav} onClose={() => setShowMobileNav(!showMobileNav)}></Overlay>
            <HeaderNavWrapper show={showMobileNav}>
                <HeaderNavItem><HeaderNavItemLink onClick={() => setShowMobileNav(false)} href={"#competitions"}>Tävlingar</HeaderNavItemLink></HeaderNavItem>
                <HeaderNavItem><HeaderNavItemLink onClick={() => setShowMobileNav(false)} href={"#players"}>Spelare</HeaderNavItemLink></HeaderNavItem>
                <HeaderNavItem><HeaderNavItemLink onClick={() => setShowMobileNav(false)} href={"#ranking"}>Ranking</HeaderNavItemLink></HeaderNavItem>
            </HeaderNavWrapper>
        </>
    )
}
