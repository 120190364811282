import styled from 'styled-components';
import { dateInBetween } from '../../../utils/helpers';
import { useState } from 'react';
import { CompetitionUnsubscribeModal } from '../../molecules/competition/CompetitionUnsubscribeModal';
import { ICourse, ICompetition } from '../../../models';
import React from "react"
import { Button } from '../default/Button';

const CompetitionUnsubscribeButtonWrapper = styled.button`
    padding:0.5rem;
    border:0;
    background:var(--accent-orange);
    color:var(--white);
`;

interface CompetitionUnsubscribeButtonProps {
    course: ICourse
    competition: ICompetition
}

export const CompetitionUnsubscribeButton = ({ course, competition }: CompetitionUnsubscribeButtonProps) => {

    const [showUnsubscribeCompetiotionModal, setShowUnsubscribeCompetiotionModal] = useState(false);

    const checkUnsubsribeButtonVisible = (startDate: string, endDate: string) => {
        let date = new Date();
        let flag = dateInBetween(startDate, endDate, date.toISOString())
        return flag;
    }

    return (
        <>
            {checkUnsubsribeButtonVisible(competition.registrationOpen, competition.registrationClose) && competition.registrationIds.length !== 0
                ? <Button background="--black" onClick={() => setShowUnsubscribeCompetiotionModal(true)}>
                    Avboka dig från {competition.competitionName}
                </Button>
                : null
            }
            {showUnsubscribeCompetiotionModal ? <CompetitionUnsubscribeModal course={course} competition={competition} visible={showUnsubscribeCompetiotionModal} onClose={() => setShowUnsubscribeCompetiotionModal(false)} /> : null}
        </>
    )
}
