import React from 'react';
import styled, { css } from 'styled-components';
import { AlignItems, Justify } from '../../../types';
import Overlay from './Overlay';
import { CloseIcon } from '../icons/CloseIcon';

const CloseButton = styled.button`
    width: 47px;
    height: 47px;
    background-color: #fff;
    z-index: 10;
    top: -9px;
    border-radius: 50%;
    right: -9px;
    position: absolute;
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    justify-content: center;
    @media screen and (max-width: 768px)
    {
        border:0;
        top:0;
        right:0;
        background-color:transparent;
    }
`;

const StyledModal = styled.div<StyledModalProps>`
  max-height: ${({ withHeader }) => withHeader ? 'inherit' : '90vh'};
  max-width: ${({ withHeader }) => withHeader ? 'inherit' : '90vw'};
  height: ${({ withHeader }) => withHeader ? '100%' : 'auto'};
  z-index: ${({ zIndex }) => zIndex};
  width: ${({ width }) => width};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height:500px;
  padding:15px;
  ${({ positionRight }) =>
        positionRight &&
        css`
            position: absolute;
            right:0;
    `}
    @media screen and (max-width: 768px)
    {
        width:95%;
        height:95%;
        max-height:inherit;
        max-width:inherit;
    }
`;

const StyledModalHeader = styled.div<StyledModalProps>`
  height:3.125rem;
  min-height:3.125rem;
  background:${({ headerBackgroundColor }) => headerBackgroundColor};
  display:flex;
  align-items:center;
  text-align:center;
  font-size:0.875rem;
  svg {
      margin-left:auto;
      position:absolute;
      right:20px;
  }
`;

const StyledModalTitle = styled.span`
  font-weight:700;
`;

interface StyledModalProps {
    zIndex?: number;
    width?: string;
    withHeader?: boolean
    headerBackgroundColor?: string
    positionRight?: boolean
}

export interface ModalProps {
    children: React.ReactNode
    visible: boolean;
    width?: string;
    alignItems?: AlignItems;
    justifyContent?: Justify;
    zIndex?: number;
    disableOverlayClick?: boolean;
    onClose: () => void;
    withHeader?: boolean
    headerBackgroundColor?: string
    title?: string
    positionRight?: boolean
}

export const Modal = ({ children, visible, width = '50rem', onClose, zIndex = 25, disableOverlayClick = false, withHeader = false, headerBackgroundColor = "var(--co-blue)", title = "", positionRight = false, ...rest }: ModalProps) => {
    if (!visible) return null;
    return (
        <>
            <Overlay visible={visible} onClose={onClose} disableClick={disableOverlayClick} zIndex={zIndex}>
                <StyledModal positionRight={positionRight} {...rest} withHeader={withHeader} zIndex={zIndex + 1} width={width}>
                    {withHeader
                        ? <StyledModalHeader headerBackgroundColor={headerBackgroundColor}>
                            <StyledModalTitle>{title}</StyledModalTitle>
                            <CloseIcon onClick={onClose} color="black" />
                        </StyledModalHeader>
                        : <CloseButton
                            data-testid="close-button"
                            onClick={onClose}
                            role="button"
                            aria-label="Stäng"
                            title="Stäng"
                            type="button"
                        >
                            <CloseIcon color="black" />
                        </CloseButton>
                    }
                    {children}
                </StyledModal>
            </Overlay>
        </>
    );
};
