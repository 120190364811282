import { IPlayer, ICompetition, ICourse } from "../../models";
import { Grid } from "../layout/GridWrapper";
import styled from "styled-components";
import React from "react";
import { NextCompetition } from "../molecules/NextCompetition";
import { Header } from "../layout/header/Header";
import { sortCompetitions, getYear } from "../../utils/helpers";
import Heading from "../atom/default/Heading";
import { Paragraph } from "../atom/default/Paragraph";

const HomeSection = styled.section`
    width:100%;
    height:auto;
    color:var(--white);
    background-image:url(https://www.nordicgolfers.com/fileadmin/user_upload/Sverige/svenske_golfbaner/Landskrona_Golfklubb/Landskrona1_Bildgalleri_1920x1080px.jpg);
    min-height:530px;
    background-size:cover;
    overflow: hidden;
    position: relative;
    @media screen and (min-width: 768px)
    {
        padding-bottom:200px;
    }
}
`;

const ImageOverlayTop = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 189, 156, 0), var(--accent-blue));
  //00bd9c
  bottom: 50%;
  opacity: 0.3;
  z-index: 2;
`;

const ImageOverlay = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  z-index: 1;
`;

const HomeWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  position: relative;
  z-index: 3;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const HomeLeft = styled.div`
  display: flex;
  width: 50%;
  flex-basis: 50%;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-basis: 100%;
  }
`;

const HomeRight = styled.div`
  display: flex;
  width: 50%;
  flex-basis: 50%;
  flex-direction: column;
  padding: 0 100px;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-basis: 100%;
    padding: 0 0 20px;
  }
`;

interface HomeTemplateProps {
  competitions: ICompetition[];
  courses: ICourse[];
}

const HomeTemplate = ({ competitions, courses }: HomeTemplateProps) => {
  const year = getYear();
  competitions = sortCompetitions(competitions);

  //let newCourses = courses.filter((course) => { return course.courseId != 0 });
  //let courseImages = newCourses.map(({ courseImage }) => courseImage);
  //let courseImage = courseImages[Math.floor(Math.random() * courseImages.length)];
  return (
    <HomeSection id="home">
      <Header />
      <Grid>
        <HomeWrapper>
          <HomeLeft>
            <Paragraph fontSize={"30px"} fontWeight="700">
              Skånetouren {year}
            </Paragraph>
          </HomeLeft>
          <HomeRight>
            <NextCompetition competitions={competitions} />
          </HomeRight>
        </HomeWrapper>
      </Grid>
      <ImageOverlay />
      <ImageOverlayTop />
    </HomeSection>
  );
};

export default HomeTemplate;
