import { returnPlayers } from "../../../utils/api/players";
import React, { useState, useEffect } from "react";
import { PlayerThumbImage } from "../player/PlayerThumbImage";
import { PlayerName } from "../player/PlayerName";
import styled from "styled-components";

const TeeTimeHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 700;
  margin-top: 5px;
`;

const TeeTimePlayers = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  oveflow-y: auto;
  max-height: 500px;
`;

const TeeTimePlayer = styled.div`
  display: flex;
  width: 25%;
  padding-right: 10px;
  align-items: center;
  img {
    margin-right: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 50%;
  }
`;

interface TeeTimeProps {
  name: string;
  playerIds: number[];
  teeTime: string;
}

export const TeeTime = ({ name, playerIds, teeTime }: TeeTimeProps) => {
  const [players, setPlayers] = useState([]);

  const filterPlayers = async (playerIds) => {
    let players = await returnPlayers(playerIds);
    setPlayers(players);
  };

  useEffect(() => {
    async function returnNumberOfPlayers() {
      await filterPlayers(playerIds);
    }
    returnNumberOfPlayers();
  }, []);

  return (
    <>
      <TeeTimeHeader>
        {name} | {teeTime}
      </TeeTimeHeader>
      <TeeTimePlayers>
        {players.map((player, index) => (
          <TeeTimePlayer key={index}>
            <PlayerThumbImage src={player.profileImage} />
            <PlayerName name={player.name} />
          </TeeTimePlayer>
        ))}
      </TeeTimePlayers>
    </>
  );
};
