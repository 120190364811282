import React from "react";
import styled from 'styled-components';

const MainWrapper = styled.div`
    display:flex;
    flex-direction:column;
    min-height: 100vh;
    margin-bottom:20px;
    @media only screen and (min-width: 768px)
    {

    }
`;

interface MainProps {
    children?: React.ReactNode;
}

export const Main = ({ children }: MainProps) => {
    return (
        <MainWrapper>
            {children}
        </MainWrapper>
    )
}
