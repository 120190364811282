import { IPlayer, ICompetition, ICourse, IPlayerScoreCard } from "../../models"
import { Grid } from "../layout/GridWrapper"
import { Player } from "../molecules/Player"
import { PageWrapper } from "../layout/PageWrapper"
import styled from 'styled-components';
import React from "react"


const PlayersSection = styled.section`
    width:100%;
    min-height:100vh;
    height:auto;
    padding-top:80px;
`;

const PlayersWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
`;


interface PlayersProps {
    players: IPlayer[]
    competitions: ICompetition[]
    courses: ICourse[]
    playersScorecards: IPlayerScoreCard[]
}

const PlayersTemplate = ({ players, competitions, courses, playersScorecards }: PlayersProps) => {
    let sortedPlayers = players.sort((a, b) => {
        return a.name.localeCompare(b.name);
    })
    sortedPlayers = sortedPlayers.filter((player) => player.id !== 32);
    return (
        <PlayersSection id="players">
            <Grid>
                <PlayersWrapper>
                    {sortedPlayers.map((player, index) => <Player key={index} competitions={competitions} courses={courses} playersScorecards={playersScorecards} player={player} />)}
                </PlayersWrapper>
            </Grid>
        </PlayersSection>
    )
}

export default PlayersTemplate
