import { IPlayer } from "../../models";
import Heading from "../atom/default/Heading";
import styled from 'styled-components';
import { PlayerThumbImage } from "../atom/player/PlayerThumbImage";
import React from "react"

const CompetitionViewPlayersWrapper = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    min-height:500px;
`;

const CompetitionViewPlayersList = styled.ul`
    display:flex;
    margin:0;
    padding:20px;
    list-style-type:none;
    flex-wrap:wrap;
    width:100%;
    max-height:600px;
    overflow-y:auto;
`;

const CompetitionViewPlayersListItem = styled.li`
    display:flex;
    align-items:center;
    flex-direction:row;
    width:50%;
    padding-bottom:5px;
    @media screen and (min-width: 768px)
    {

    }
`;

const CompetitionViewPlayersListItemName = styled.span`
   padding:0 10px;
   display:block;
`;

interface PlayerListProps {
    players: IPlayer[]
}

export const PlayerList = ({ players }: PlayerListProps) => {
    players = players.filter((player) => player.id != 32);
    return (
        <CompetitionViewPlayersWrapper>
            <Heading>Anmälda</Heading>
            <CompetitionViewPlayersList>
                {players.map((player: IPlayer, index) =>
                    <CompetitionViewPlayersListItem key={index}>
                        <PlayerThumbImage src={player.profileImage} />
                        <CompetitionViewPlayersListItemName>{player.name}</CompetitionViewPlayersListItemName>
                    </CompetitionViewPlayersListItem>)}
            </CompetitionViewPlayersList>
        </CompetitionViewPlayersWrapper>
    )
}
