import { Modal } from "../../atom/default/Modal"
import { ICompetition } from "../../../models"
import { PlayerList } from "../PlayerList"
import { useState, useEffect } from "react";
import { returnPlayers } from "../../../utils/api/players";
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { isDateBeforeToday, sortPlayers } from "../../../utils/helpers";
import { TeeTimes } from "./TeeTimes";

interface CompetitionViewPlayersModalProps {
    visible: boolean
    onClose: () => void
    competition: ICompetition
}

export const CompetitionViewPlayersModal = ({ visible, onClose, competition }: CompetitionViewPlayersModalProps) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [players, setPlayers] = useState([]);

    let showTeeTimes = competition.competitionTeeTimes.length !== 0;

    const filterPlayers = async () => {
        let players = await returnPlayers(competition.registrationIds);
        players = players.filter((id) => id != 32);
        players = sortPlayers(players);
        setPlayers(players);
        setModalVisible(true);
    }

    useEffect(() => {
        async function getPlayers() {
            await filterPlayers();
        }
        if (visible) {
            getPlayers()
        }
    }, [visible])

    return (
        <Modal visible={isModalVisible} onClose={onClose}>
            {showTeeTimes
                ? <TeeTimes competitionTeeTimes={competition.competitionTeeTimes} />
                : <PlayerList players={players} />
            }
        </Modal>
    )
}
