import styled from 'styled-components';
import { FontWeight, Direction, Justify, AlignItems } from '../../../types';
import React from "react"

interface ParagraphStyledProps {
    fontWeight?: FontWeight
    fontSize?: string
    direction?: Direction
    justifyContent?: Justify
    alignItems: AlignItems
    padding?: string
}

const ParagraphWrapper = styled.span<ParagraphStyledProps>`
    margin:0;
    padding:${({ padding }) => padding};;
    font-size:${({ fontSize }) => fontSize};
    font-weight:${({ fontWeight }) => fontWeight};
    flex-direction:${({ direction }) => direction};
    display:flex;
    align-items:${({ alignItems }) => alignItems};
    justify-content:${({ justifyContent }) => justifyContent};
`;

interface ParagraphProps {
    children: React.ReactNode
    fontWeight?: FontWeight
    fontSize?: string
    direction?: Direction
    justifyContent?: Justify
    alignItems?: AlignItems
    padding?: string
    onClick?: () => void;
}

export const Paragraph = ({ children, fontWeight = "400", fontSize = "14px", direction = "row", justifyContent = "flex-start", alignItems = "center", padding = "0 0 0.5rem", onClick }: ParagraphProps) => {
    return (
        <ParagraphWrapper onClick={onClick} direction={direction} justifyContent={justifyContent} alignItems={alignItems} fontWeight={fontWeight} fontSize={fontSize} padding={padding}>
            {children}
        </ParagraphWrapper>
    )
}
