import styled from 'styled-components';
import React from "react"
import { getYear } from '../../../../utils/helpers';

const HeaderTitleWrapper = styled.div`
    display:flex;
    font-weight:300;
    align-items:center;
    justify-content:center;
    width:100%;
    color:var(--white);
    @media screen and (min-width: 768px)
    {
        display:none;
        //justify-content:flex-start;
    }
`;

export const HeaderTitle = () => {
    const year = getYear();
    return (
        <HeaderTitleWrapper>Skånetouren {year}</HeaderTitleWrapper>
    )
}
