import styled from 'styled-components';
import React from "react"

const DividerWrapper = styled.div`
    display:flex;
    flex-direction:row;
    border:0;
    height: 2rem;
    width:100%;
`;

export const Divider = () => {
    return (
        <DividerWrapper />
    )
}
