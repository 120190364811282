import { ICompetitionTeeTime, IPlayer } from "../../../models"
import React from "react";
import { TeeTime } from "../../atom/competition/TeeTime";
import Heading from "../../atom/default/Heading";
import styled from "styled-components";

const TeeTimesWrapper = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    min-height:500px;
    overflow-y:auto;
`;

interface TeeTimesProps {
    competitionTeeTimes: ICompetitionTeeTime[]
}

export const TeeTimes = ({ competitionTeeTimes }: TeeTimesProps) => {
    return (
        <TeeTimesWrapper>
            <Heading>Startfält</Heading>
            {competitionTeeTimes && competitionTeeTimes.map((teeTime, index) =>
                <TeeTime key={index} name={teeTime.name} playerIds={teeTime.playerIds} teeTime={teeTime.teeTime} />
            )}
        </TeeTimesWrapper>
    )
}
