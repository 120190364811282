import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { ICompetition } from '../../../models';
import { CompetitionViewPlayersModal } from '../../molecules/competition/CompetitionViewPlayersModal';
import React from "react"
import { returnPlayers } from '../../../utils/api/players';

const CompetitionViewPlayersButtonWrapper = styled.button`
    padding:0.5rem;
    border:0;
    background:var(--accent-orange);
    color:var(--white);
    cursor:pointer;
    margin-left:10px;
`;

interface CompetitionViewPlayersButtonProps {
    competition: ICompetition
}

export const CompetitionViewPlayersButton = ({ competition }: CompetitionViewPlayersButtonProps) => {
    const [showViewPlayersModal, setShowViewPlayersModalModal] = useState(false);
    const [numberOfPlayers, setNumberOfPlayers] = useState(0);

    const filterPlayers = async (ids: number[]) => {
        let players = await returnPlayers(ids);
        players = players.filter((player) => player.id != 32);
        setNumberOfPlayers(players.length);
    }

    useEffect(() => {
        async function returnNumberOfPlayers() {
            await filterPlayers(competition.registrationIds);
        }
        returnNumberOfPlayers()
    }, [])


    return (
        <>
            <CompetitionViewPlayersButtonWrapper onClick={() => setShowViewPlayersModalModal(true)}>
                {numberOfPlayers}
            </CompetitionViewPlayersButtonWrapper>
            {showViewPlayersModal ? <CompetitionViewPlayersModal competition={competition} visible={showViewPlayersModal} onClose={() => setShowViewPlayersModalModal(false)} /> : null}
        </>
    )
}
