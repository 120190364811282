import { Modal } from "../../atom/default/Modal";
import {
  ICompetition,
  ICourse,
  IPlayer,
  IPlayerScoreCard,
  ICourseHole,
  IScoreCard,
} from "../../../models";
import { PlayerList } from "../PlayerList";
import { useState, useEffect } from "react";
import { returnPlayers } from "../../../utils/api/players";
import React from "react";
import { Paragraph } from "../../atom/default/Paragraph";
import Heading from "../../atom/default/Heading";
import {
  returnCompetition,
  returnCourse,
  returnPlayerScorecardHoleScore,
  setScoreColor,
  returnPlayerPointsBirdies,
  sortPlayerscorecards,
  isHidden,
} from "../../../utils/helpers";
import styled from "styled-components";
import { ScoreCardColors, AlignItems, Justify } from "../../../types";
import Row from "../../atom/default/Row";
import { PlayerThumbImage } from "../../atom/player/PlayerThumbImage";
import { useMediaQuery } from "react-responsive";
import { Button } from "../../atom/default/Button";
import { ScoreColors } from "../../atom/default/ScoreColors";
import { Scorecard } from "../forms/Scorecard";
import { documentId } from "firebase/firestore";

interface PlayerScoreStyledProps {
  color?: ScoreCardColors;
  alignItems?: AlignItems;
  justifyContent?: Justify;
  active?: boolean;
  fontWeight?: string;
}

const ViewPlayerWrapper = styled.div``;

const PlayerScorecardsWrapper = styled.div`
  overflow-y: auto;
  max-height: 450px;
`;

const PlayerScorecardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
`;

const PlayerScorecard = styled.div<PlayerScoreStyledProps>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 10px;

  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
`;

const PlayerScorecardHole = styled.div<PlayerScoreStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  font-size: 0.75rem;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 5px;
  }
`;

const PlayerScorecardHoleText = styled.span<PlayerScoreStyledProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const PlayerScorecardHoleResult = styled.span<PlayerScoreStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  width: 100%;
  background: ${({ color }) => `var(${color})`};
  border-radius: 5px;
  color: ${({ color }) =>
    color === "--score-par" || color === "--transparent"
      ? "var(--black)"
      : "var(--white)"};
  width: 20px;
  height: 20px;
  font-weight: 700;
`;

interface ViewPlayerModalProps {
  visible: boolean;
  onClose: () => void;
  player: IPlayer;
  competitions: ICompetition[];
  courses: ICourse[];
  playerScoreCards: IPlayerScoreCard[];
}

export const ViewPlayerModal = ({
  visible,
  onClose,
  player,
  competitions,
  courses,
  playerScoreCards,
}: ViewPlayerModalProps) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [showScorecard, setShowScorecard] = useState({});

  let years = Array.from(
    new Set(
      playerScoreCards.map((scorecard) =>
        new Date(scorecard.competitionDate).getFullYear()
      )
    )
  );
  years = years.sort((a, b) => a - b);
  playerScoreCards = sortPlayerscorecards(playerScoreCards);
  let totalPoints = returnPlayerPointsBirdies(playerScoreCards);

  const toggleScorecard = (id) => {
    setShowScorecard((prevshowScorecard) => ({
      ...prevshowScorecard,
      [id]: !prevshowScorecard[id],
    }));
  };

  const setScorecardTotal = (course, scoreCard) => {
    let scoreTotals = {
      out: 0,
      in: 0,
    };
    let scoreTotal = 0;
    scoreCard.scoreCard.map((item, index) => {
      let score = item.score !== undefined ? item.score : 0;
      scoreTotal += score;
      if (index === 8) {
        scoreTotals["out"] = scoreTotal;
        scoreTotal = 0;
      }
      if (index === 17) {
        scoreTotals["in"] = scoreTotal;
      }
    });

    let scoreCardsExtra = {
      out: (
        <PlayerScorecardHole key={"out"} alignItems="center">
          <PlayerScorecardHoleText fontWeight={"700"}>
            Ut
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleText fontWeight={"400"}>
            &nbsp;
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleText fontWeight={"400"}>
            {course.courseOut}
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleResult color={"--transparent"}>
            {scoreTotals.out}
          </PlayerScorecardHoleResult>
        </PlayerScorecardHole>
      ),
      in: (
        <PlayerScorecardHole key={"in"} alignItems="center">
          <PlayerScorecardHoleText fontWeight={"700"}>
            In
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleText fontWeight={"400"}>
            &nbsp;
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleText fontWeight={"400"}>
            {course.courseIn}
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleResult color={"--transparent"}>
            {scoreTotals.in}
          </PlayerScorecardHoleResult>
        </PlayerScorecardHole>
      ),
      total: (
        <PlayerScorecardHole key={"total"} alignItems="center">
          <PlayerScorecardHoleText fontWeight={"700"}>
            Total
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleText fontWeight={"400"}>
            &nbsp;
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleText fontWeight={"400"}>
            {course.courseOut + course.courseIn}
          </PlayerScorecardHoleText>
          <PlayerScorecardHoleResult color={"--transparent"}>
            {scoreTotals.out === 0 && scoreTotals.in === 0
              ? 0
              : scoreTotals.out + scoreTotals.in}
          </PlayerScorecardHoleResult>
        </PlayerScorecardHole>
      ),
    };

    return scoreCardsExtra;
  };

  const setPlayerScoreCard = (scoreCard: IPlayerScoreCard, index: any) => {
    let competition: ICompetition = returnCompetition(
      scoreCard.competitionId,
      competitions
    ) as ICompetition;
    if (competition) {
      let course = returnCourse(competition?.courseId, courses);
      let extra = setScorecardTotal(course, scoreCard);

      const setPlayerHoleScore = (hole: ICourseHole, index: number) => {
        let holeScore = returnPlayerScorecardHoleScore(
          hole.hole as number,
          scoreCard.scoreCard
        );
        let color: ScoreCardColors = setScoreColor(
          hole.holePar,
          holeScore?.score as number
        );

        return (
          <React.Fragment key={`row${index}`}>
            <PlayerScorecardHole key={index} alignItems="center">
              <PlayerScorecardHoleText fontWeight={"700"}>
                {hole.hole}
              </PlayerScorecardHoleText>
              <PlayerScorecardHoleText fontWeight={"400"}>
                {hole.holeIndex}
              </PlayerScorecardHoleText>
              <PlayerScorecardHoleText fontWeight={"400"}>
                {hole.holePar}
              </PlayerScorecardHoleText>
              <PlayerScorecardHoleResult
                title={color.substring(8, color.length)}
                color={color}
              >
                {holeScore?.score}
              </PlayerScorecardHoleResult>
            </PlayerScorecardHole>
            {index === 8 ? extra.out : null}
            {index === 17 ? (
              <>
                {extra.in}
                {extra.total}
              </>
            ) : null}
          </React.Fragment>
        );
      };

      return (
        <PlayerScorecardWrapper key={index}>
          <Row
            onClick={
              scoreCard.scoreCard.length === 0
                ? null
                : () => toggleScorecard(competition.competitionId)
            }
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Paragraph fontWeight={"400"} fontSize={"11px"} padding={"0"}>
              {competition?.competitionDate}
              <Paragraph
                fontSize={"12px"}
                fontWeight={"700"}
                padding={"0 0 0 0.5rem"}
              >
                {" "}
                {competition?.competitionName}
              </Paragraph>
            </Paragraph>
            <Paragraph
              fontSize={"11px"}
              padding={"0 0 0 0.5rem"}
              justifyContent={isTabletOrMobile ? "space-between" : "flex-start"}
            >
              Poäng {scoreCard?.points} | Birdies {scoreCard?.birdies}
            </Paragraph>
          </Row>
          <PlayerScorecard active={showScorecard[competition.competitionId]}>
            <PlayerScorecardHole
              alignItems={"flex-start"}
              justifyContent="center"
            >
              <PlayerScorecardHoleText fontWeight={"700"}>
                Hål
              </PlayerScorecardHoleText>
              <PlayerScorecardHoleText fontWeight={"700"}>
                Index
              </PlayerScorecardHoleText>
              <PlayerScorecardHoleText fontWeight={"700"}>
                Par
              </PlayerScorecardHoleText>
              <PlayerScorecardHoleText fontWeight={"700"}>
                Resultat
              </PlayerScorecardHoleText>
            </PlayerScorecardHole>
            {course?.courseHoles.map((hole: ICourseHole, index) => {
              return setPlayerHoleScore(hole, index);
            })}
          </PlayerScorecard>
        </PlayerScorecardWrapper>
      );
    }
  };

  const showYear = (idx) => {
    const elem = document.getElementById(`yearScores-${idx}`);
    elem.style.display = isHidden(elem) ? "block" : "none";
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <ViewPlayerWrapper>
        <Row
          padding={"0 0 10px"}
          mobilePadding={"0 0 10px"}
          alignItems="center"
          justifyContent={"flex-start"}
        >
          <PlayerThumbImage src={player.profileImage} />
          <Heading padding={"0 0 0 10px"} align="left">
            {player.name}
          </Heading>
        </Row>
        <Row justifyContent="flex-start">
          <Paragraph fontWeight={"700"}>Poäng:</Paragraph>
          <Paragraph padding={"0 0 0.5rem 0.5rem"}>
            {totalPoints.points}
          </Paragraph>
          <Paragraph fontWeight={"700"} padding={"0 0 0.5rem 0.5rem"}>
            Birdies:
          </Paragraph>
          <Paragraph padding={"0 0 0.5rem 0.5rem"}>
            {totalPoints.birdies}
          </Paragraph>
        </Row>
        <Paragraph fontWeight="700">Tävlingar</Paragraph>
        <PlayerScorecardsWrapper>
          {years &&
            years.map((year, idx) => {
              const flag = years.length - 1 === idx;
              return (
                <div key={idx}>
                  <Paragraph
                    padding="0.5rem 0"
                    fontWeight="700"
                    onClick={() => showYear(idx)}
                  >
                    {year}
                  </Paragraph>
                  <div
                    id={`yearScores-${idx}`}
                    style={{ display: flag ? "block" : "none" }}
                  >
                    {playerScoreCards &&
                      playerScoreCards
                        .filter(
                          (playScore) =>
                            playScore.competitionDate.substring(0, 4) ===
                            year.toString()
                        )
                        .map((scoreCard: IPlayerScoreCard, index: number) =>
                          setPlayerScoreCard(scoreCard, index)
                        )}
                  </div>
                </div>
              );
            })}
        </PlayerScorecardsWrapper>
        {playerScoreCards.length === 0 ? null : (
          <Row>
            <ScoreColors />
          </Row>
        )}
      </ViewPlayerWrapper>
    </Modal>
  );
};
