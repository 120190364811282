/*import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

var firebaseConfig = {
    apiKey: "AIzaSyAsfxsZPlpWlWQnrSwjuq5bjvo4tY-xOCA",
    authDomain: "stockholmskane2022.firebaseapp.com",
    projectId: "stockholmskane2022",
    databaseURL: "https://stockholmskane2022-default-rtdb.europe-west1.firebasedatabase.app/",
    storageBucket: "stockholmskane2022.appspot.com",
    messagingSenderId: "352155720872",
    appId: "1:352155720872:web:d49bcffdc03e08e51b369d"
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export const database = getDatabase(app);
*/
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
const firebaseApp = initializeApp({
    apiKey: "AIzaSyAPmWj3medP0VRK-Kx-1mdJRyp-10sFwg0",
    authDomain: "skanetouren-6ebac.firebaseapp.com",
    projectId: "skanetouren-6ebac",
});

const db = getFirestore();

export { db }