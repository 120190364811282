import styled from 'styled-components';
import React from "react"

const PlayerThumbImageWrapper = styled.div`
    img {
        width:30px;
        height:30px;
        border-radius:5px;
    }
`;

interface PlayerThumbImageProps {
    src: string
}

export const PlayerThumbImage = ({ src }: PlayerThumbImageProps) => {
    return (
        <PlayerThumbImageWrapper>
            <img src={src} alt="profile-image" />
        </PlayerThumbImageWrapper>
    )
}
