import React from "react"
import styled from "styled-components";
import { Grid } from "./GridWrapper";
import { InstagramIcon } from "../atom/icons/InstagramIcon";
import { getYear } from "../../utils/helpers";

const FooterWrapper = styled.footer`
    height:40px;
    background:var(--black);
    display: flex;
    width: 100%;
    align-items:center;
    justify-content:center;
    color:var(--white);
    flex-direction:row;
`;

const FooterDiv = styled.div`
    display: flex;
    align-items:center;
    justify-content:flex-start;
    @media screen and (max-width: 768px)
    {
        justify-content:center;
    }
`;

const FooterSocialIcons = styled.div`
    display:flex;
    padding-left:10px;
`;

export const Footer = () => {
    const year = getYear();
    return (
        <FooterWrapper>
            <Grid>
                <FooterDiv>
                    Skånetouren {year}
                    <FooterSocialIcons>
                        <a href="https://instagram.com/skanetouren/" target="_blank"><InstagramIcon color="whitefill" /></a>
                    </FooterSocialIcons>
                </FooterDiv>
            </Grid>
        </FooterWrapper>
    )
}
