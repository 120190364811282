import styled from 'styled-components';
import { ICourse, ICompetition, ICourseSlope } from '../../../models';
import Row from '../../atom/default/Row';
import { Paragraph } from '../../atom/default/Paragraph';
import Heading from '../../atom/default/Heading';
import { Divider } from '../../atom/default/Divider';
import { CompetitionUnsubscribeButton } from '../../atom/competition/CompetitionUnsubscribeButton';
import { CompetitionViewPlayersButton } from '../../atom/competition/CompetitionViewPlayersButton';
import React, { useEffect, useState } from "react"
import { isDateBeforeToday, isDateAfterToday, sortPlayers } from '../../../utils/helpers';
import { returnPlayers } from '../../../utils/api/players';
import Input from '../../atom/default/Input';
import { Button } from '../../atom/default/Button';
import { Modal } from '../../atom/default/Modal';

const CourseInfoBoxWrapper = styled.div`
    display:flex;
    width:100%;
    flex-basis:100%;
    flex-direction:column;
    padding-top:1rem;
    @media screen and (min-width: 768px)
    {
        width:50%;
        flex-basis:50%;
        padding-left:1rem;
        padding-top:0;
    }
`;

const CourseGuideLink = styled.a`
    cursor:pointer;
`;

const CompetitionSlopeWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    min-height:500px;
`;

interface CourseInfoBoxProps {
    course: ICourse
    competition: ICompetition
}

const CourseInfoBox = ({ course, competition }: CourseInfoBoxProps) => {
    let showTeeTimes = competition.competitionTeeTimes.length !== 0
    const [player, setPlayer] = useState("");
    const [handicap, setHandicap] = useState(0);
    const [strokes, setStrokes] = useState(0);
    const [viewSlopeModal, setViewSlopeModal] = useState(false);

    const countStrokes = () => {
        const slope = course.courseSlope.find((row: ICourseSlope) => row.tee === competition.competitionTee)
        const handicapScore = handicap * slope.slope / 113;
        let par = course.coursePar;
        if (typeof par === 'string') {
            par = parseFloat(par);
        }
        const courseRate = slope.courseRating - par;
        setStrokes(Math.ceil(handicapScore + courseRate));
    }

    const filterPlayers = async () => {
        let playerId = parseFloat(competition.winner);
        let players = await returnPlayers([playerId]);
        if (players.length !== 0) {
            players = sortPlayers(players);
            setPlayer(players[0].name);
        }
    }

    useEffect(() => {
        async function getPlayers() {
            await filterPlayers();
        }
        if (competition.winner !== "") {

            getPlayers()
        }
    }, [competition.winner])


    useEffect(() => {
        if (viewSlopeModal) {
            setStrokes(0);
        }
    }, [viewSlopeModal])

    return (
        <CourseInfoBoxWrapper>
            <Row justifyContent='space-between'>
                <Paragraph>{competition.competitionDate}</Paragraph>
                <Paragraph fontSize="11px">{`Par ${course.coursePar}`}&nbsp;{`| Tee ${competition.competitionTee}`}</Paragraph>
            </Row>
            <Heading>{competition.competitionName}</Heading>
            <Paragraph>{course.courseDescription}</Paragraph>
            <Row direction="row" justifyContent="space-between">
                {course.courseGuide === "" ? null : <Paragraph fontWeight="700"><CourseGuideLink href={course.courseGuide} target="_blank">Se banguide</CourseGuideLink></Paragraph>}
                {course.hasOwnProperty('courseSlope') && course.courseSlope.length !== 0 && player === "" ? <Paragraph fontWeight="700"><CourseGuideLink onClick={() => setViewSlopeModal(true)}>Beräkna antal slag</CourseGuideLink></Paragraph> : null}
            </Row>
            <Modal visible={viewSlopeModal} onClose={() => setViewSlopeModal(false)}>
                <CompetitionSlopeWrapper>
                    <Row alignItems="center" justifyContent="center" padding="20px" direction="column">
                        <Heading align="center">Beräkna antal slag (Beta)</Heading>
                        <Input id="slopeHandicap" inputTitle={"Ditt handicap"} name="slopeHandicap" type="number" onChange={(e) => {
                            const value = e.target.value.replace(/,/g, '.')
                            setHandicap(parseFloat(value))
                        }} />
                        <Button background="--black" onClick={countStrokes}>Beräkna</Button>
                        <Paragraph padding="10px 0">Du har {strokes} extra slag på {course.courseName}</Paragraph>
                    </Row>
                </CompetitionSlopeWrapper>
            </Modal>
            <Row>
                <Paragraph>Avbokningen öppen {competition.registrationOpen} - {competition.registrationClose}</Paragraph>
            </Row>
            {player === ""
                ? <>
                    <Divider />
                    <Paragraph>{showTeeTimes ? 'Startfält' : 'Anmälda'}<CompetitionViewPlayersButton competition={competition} /></Paragraph>
                </>
                : null
            }
            <Divider />
            {player !== "" ? <><Paragraph fontWeight={"700"}>Vinnare:</Paragraph> <Paragraph>{player}</Paragraph></> : null}
            <CompetitionUnsubscribeButton course={course} competition={competition} />
        </CourseInfoBoxWrapper>
    )
}

export default CourseInfoBox
