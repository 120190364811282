import { collection, getDocs, doc, updateDoc, addDoc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase'
import { ICompetition } from "../../models";

export const getCompetition = async (competitions: ICompetition[], id: number) => {
    return competitions.find((competition: ICompetition) => competition.competitionId == id);
}

export const getCompetitions = async () => {
    let data: any = [];
    const querySnapshot = await getDocs(collection(db, "competitions"));
    querySnapshot.forEach((doc) => {
        data.push(doc.data());
    });
    return data;
}

export const postCompetition = async (competition: ICompetition) => {
    try {
        const docRef = await addDoc(collection(db, "competitions"), competition);
        competition.docId = docRef.id
        let obj = { ...competition }
        let result = await updateCompetition(obj);
    } catch (e) {
    }
}

export const updateCompetition = async (competition: ICompetition) => {
    const ref = await doc(db, "competitions", `${competition.docId}`);
    return await updateDoc(ref, { ...competition })
        .then(() => {
            return Promise.resolve(true);
        })
        .catch((error) => {
            return Promise.resolve(false);
        });
}

export const deleteCompetition = async (docId: string) => {
    const ref = await doc(db, "competitions", `${docId}`);
    return await deleteDoc(ref)
        .then(() => {
            return Promise.resolve(true);
        })
        .catch((error) => {
            return Promise.resolve(false);
        });
}
